import styles from "./Home.module.css";
import ContactFormModal from "../../components/Contact-Form-Modal/ContactFormModal";
import WeddingCouple from "../../assets/wedding-couple.svg";
import aboutusImage1 from "../../assets/images/about-1.jpg";
import aboutusImage2 from "../../assets/images/about-2.jpg";
import aboutusImage3 from "../../assets/images/about-3.jpg";
import portfolioImage1 from "../../assets/images/portfolio-1.jpg";
import portfolioImage2 from "../../assets/images/portfolio-2.jpg";
import portfolioImage3 from "../../assets/images/portfolio-3.jpg";
import facebookIcon from "../../assets/facebook.svg";
import instagramIcon from "../../assets/instagram.svg";
import youtubeIcon from "../../assets/youtube.svg";
import Couple from "../../assets/couple.svg";
import Services from "../../assets/images/services.jpg";
import Gallery from "../../components/Gallery/Gallery";
import { useEffect, useState } from "react";
import axios from "axios";
import FormFields from "../../components/Form-Fields/FormFields";

const Home = () => {
  const [images, setImages] = useState([]);

  const fetchImages = async () => {
    try {
      const res = await axios.get("/db.json");
      const data = res.data; // Use res.data to access the parsed JSON data
      console.log(data.homeGallery);
      setImages(data.homeGallery);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    fetchImages();
  }, []);

  return (
    <>
      <section className={styles.home_pattern}>
        <div className="container">
          <div className="row">
            <div className={styles.homepage_text}>
              <h1>We Capture the story of your Wedding Day</h1>
              <p>
                Sndy Photography, we are dedicated to creating beautiful,
                timeless images that capture the essence of a moment.
                <br />
                <span className="hide_text_mob">
                  With a focus on story-telling, we strive to give our clients
                  an experience that is unique and special.
                </span>
              </p>
              <ContactFormModal modalId="exampleModal1" />
              <div className={styles.iconContainer}>
                <a
                  href="https://www.facebook.com/profile.php?id=100088380990126&mibextid=kFxxJD"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className={styles.icon}
                    src={facebookIcon}
                    alt="facebook Icon"
                  ></img>
                </a>
                <a
                  href="https://www.instagram.com/sandypixelplace?igsh=dGg3YzB3Z2ZnZDF4"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className={styles.icon}
                    src={instagramIcon}
                    alt="instagram Icon"
                  ></img>
                </a>
                <img
                  className={styles.icon}
                  src={youtubeIcon}
                  alt="youtube Icon"
                ></img>
              </div>
            </div>
            <div className={`${styles.homepage_img} text-center`}>
              <img src={WeddingCouple} alt="Wedding Couple" />
            </div>
          </div>
        </div>
      </section>
      <section className="mt-100">
        <div className="container">
          <div className={`${styles.text_heading} row`}>
            <h2>About Us</h2>
            <p>
              At Sndy Photography, we believe that photography is more than just
              taking pictures—it's about capturing the essence of the moment,
              telling a story, and creating memories that last a lifetime.
              <span className="hide_text_mob">
                Our team of passionate and experienced photographers is
                dedicated to providing exceptional photography services tailored
                to your unique needs.
              </span>
            </p>
          </div>
          <div className={`${styles.aboutus_Img} row`}>
            <div className="col-md-4">
              <div className={styles.aboutus_Image}>
                <img
                  src={aboutusImage3}
                  alt="Reception"
                  className="img-fluid"
                />
                <h5>Haldi</h5>
              </div>
            </div>
            <div className="col-md-4">
              <div className={styles.aboutus_Image}>
                <img
                  src={aboutusImage2}
                  alt="Reception"
                  className="img-fluid"
                />
                <h5>Wedding</h5>
              </div>
            </div>
            <div className="col-md-4">
              <div className={styles.aboutus_Image}>
                <img
                  src={aboutusImage1}
                  alt="Reception"
                  className="img-fluid"
                />
                <h5>Reception</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={`${styles.home_gallery_div} mt-100`}>
        <div className="container">
          <div className="row">
            <div className={styles.text_heading}>
              <h2>Take A Step Into Our Enchanting Memories</h2>
              <p>
                Explore a curated selection of our favorite images from various
                projects. Each photograph in this section represents our
                commitment to excellence and our passion for capturing the
                essence of every moment. From intimate portraits to vibrant
                celebrations, our portfolio highlights the versatility and
                creativity we bring to our work.
              </p>
            </div>
            <div className={styles.home_gallery}>
              <Gallery images={images} />
            </div>
          </div>
        </div>
      </section>
      <section className={`${styles.home_sevices_div} mt-100`}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className={styles.text_heading}>
                <h2>Capture your story the way you think</h2>
              </div>
              <div className={styles.services_heading}>
                <div className="desktop_hide">
                  <img
                    src={Services}
                    alt="wedding couple"
                    className="img-fluid"
                  />
                </div>
                <div>
                  <h5>Wedding Photography</h5>
                  <p>
                    Every moment, from the oaths to the acceptance, will be
                    captured by our team of skilled wedding photographers, who
                    will make sure that your love story is captured by amazing
                    photographs.
                  </p>
                </div>
                <div>
                  <h5>Professional Editing</h5>
                  <p>
                    We are concerned about the importance of additional
                    processing in photography. Your photos can be improved and
                    enhanced by our skilled editors, who will turn them into
                    beautiful works of creativity that perfectly capture your
                    vision
                  </p>
                </div>
                <div>
                  <h5>Cinematic Moments</h5>
                  <p>
                    For those looking to add a cinematic touch to their
                    memories, our cinematography services will bring your story
                    to life with stunning visuals, storytelling, and sound.
                  </p>
                </div>
                <div>
                  <h5>Fashion Photography</h5>
                  <p>
                    Whether you’re a model, designer, or simply looking to
                    update your portfolio, our fashion photography services will
                    capture your personality, professionalism, and uniqueness
                    with creative skills.
                  </p>
                </div>
                <ContactFormModal modalId="exampleModal1" />
              </div>
            </div>
            <div className="col-md-6 mob_hide">
              <img src={Services} alt="wedding couple" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>
      <section className={`${styles.portfolio_div} mt-100`}>
        <div className="container">
          <div className="row desktop_hide">
            <div className={styles.text_heading}>
              <h2>
                See Our Latest
                <br /> <span>Portfolio</span> Projects
              </h2>
            </div>
          </div>
          <div className="row">
            <div className={`${styles.portfolio_card} col-md-4`}>
              <img
                className="img-fluid"
                src={portfolioImage1}
                alt="Portfolio"
              />
              <div className={styles.portfolio_heading}>
                <h5>BUSHRA & SIKANDER</h5>
                <span>19/06/2024</span>
              </div>
              <p>
                Your special day deserves to be captured with thought,
                creativity, and quality. Every moment, from the oaths to the
                acceptance, will be captured by our team of skilled wedding
                photographers, who will make sure that your love story is
                captured by amazing photographs.
              </p>
            </div>
            <div className={`${styles.portfolio_card} col-md-4 mt-100`}>
              <img
                className="img-fluid"
                src={portfolioImage2}
                alt="Portfolio"
              />
              <div className={styles.portfolio_heading}>
                <h5>GEET & ASHOK</h5>
                <span>11/07/2024</span>
              </div>
              <p>
                Your special day deserves to be captured with thought,
                creativity, and quality. Every moment, from the oaths to the
                acceptance, will be captured by our team of skilled wedding
                photographers, who will make sure that your love story is
                captured by amazing photographs.
              </p>
            </div>
            <div className={`${styles.portfolio_card} col-md-4`}>
              <div className="row mob_hide">
                <div className={styles.text_heading}>
                  <h2>
                    See Our Latest
                    <br /> <span>Portfolio</span> Projects
                  </h2>
                </div>
              </div>
              <div className={styles.portfolio_last_card}>
                <img
                  className="img-fluid"
                  src={portfolioImage3}
                  alt="Portfolio"
                />
                <div className={styles.portfolio_heading}>
                  <h5>GURPREET & POONAM</h5>
                  <span>25/05/2024</span>
                </div>
                <p>
                  Your special day deserves to be captured with thought,
                  creativity, and quality. Every moment, from the oaths to the
                  acceptance, will be captured by our team of skilled wedding
                  photographers, who will make sure that your love story is
                  captured by amazing photographs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-100">
        <div className="container">
          <div className={`${styles.form} row`}>
            <div className="col-md-6 mob_hide">
              <div className={styles.form_left}>
                <div className={styles.text_heading}>
                  <h2>
                    Hi,
                    <br />
                    We are Sandy Photography
                  </h2>
                  <p>Call us : 8295278827</p>
                  <p>Mail us : sndyphotographya1@gmail.com.</p>
                </div>
                <div className={styles.couple}>
                  <img src={Couple} alt="couple" />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className={`${styles.form_right} text_heading`}>
                <h2>Contact Us</h2>
                <p>Beautiful Moments Is Everything</p>
                <FormFields isModal={false} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
