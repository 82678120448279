import React from "react";
import styles from "./About.module.css";
import photosAlbum from "../../assets/images/about-us-page.png";
import weddingService from "../../assets/images/wedding-service.jpg";
import editingService from "../../assets/images/editing-service.jpg";
import cinematographyService from "../../assets/images/cinematography-service.jpg";
import fashionService from "../../assets/images/fashion-photography.jpg";
import style from "../Home/Home.module.css";
import FormFields from "../../components/Form-Fields/FormFields";
import Couple from "../../assets/couple.svg";

const AboutUs = () => {
  return (
    <>
      <section className={styles.about_div}>
        <div className="container">
          <div className="row">
            <div className={styles.about_text}>
              <h1>About Us</h1>
              <p>
                Founded by Delhi-based photography enthusiasts, Sandeep in 2022,
                At Sndy Photography, we understand that every moment is unique
                and deserves to be captured with care and creativity. Our team
                of skilled photographers is dedicated to turning your special
                occasions and important milestones into timeless memories.{" "}
                <span className="hide_text_mob">
                  Our passion for photography creates memories in every moment.
                  We take pride in offering a wide range of photography services
                  like wedding, cinematic moments, professional editing, product
                  photography, and fashion photography.
                </span>
              </p>
            </div>
            <div className={styles.about_img}>
              <img src={photosAlbum} alt="photos-album" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>
      <section className={styles.services}>
        <div className="container">
          <div className="row">
            <div className={`${styles.text_heading} row`}>
              <h2>Our Services</h2>
              <p>
                We get inspired everyday by moments from the people we click. We
                have curated the best shots, poses, dresses and spots for you to
                binge on. Get some ideas and inspire your thoughts for your next
                trip or any upcoming occasion.
              </p>
            </div>
          </div>
          <div className={`${styles.services_main} row`}>
            <div className="col-md-3">
              <div className={styles.service_div}>
                <img
                  src={weddingService}
                  alt="Wedding Photography"
                  className="img-fluid"
                />
              </div>
              <h5>Wedding Photography</h5>
              <p>
                Your wedding day is one of the most significant days of your
                life. We strive to capture the essence of your love story with a
                blend of candid and posed shots that reflect the emotions and
                beauty of your celebration.
              </p>
            </div>
            <div className="col-md-3">
              <div className={styles.service_div}>
                <img
                  src={editingService}
                  alt="Editing Services"
                  className="img-fluid"
                />
              </div>
              <h5>Professional Editing</h5>
              <p>
                With our professional editing services, we meticulously enhance
                each image to bring out its full potential. From color
                correction to retouching, we ensure that every photograph we
                deliver is polished to perfection, reflecting the highest
                standards of quality.
              </p>
            </div>
            <div className="col-md-3">
              <div className={styles.service_div}>
                <img
                  src={cinematographyService}
                  alt="Cinematographyy"
                  className="img-fluid"
                />
              </div>
              <h5>Cinematic Moments</h5>
              <p>
                Life is full of cinematic moments, and we specialize in turning
                these into captivating visual stories. We use creative
                techniques and artistic compositions to make your moments feel
                like scenes from a movie.
              </p>
            </div>
            <div className="col-md-3">
              <div className={styles.service_div}>
                <img
                  src={fashionService}
                  alt="Fashion Photography"
                  className="img-fluid"
                />
              </div>
              <h5>Fashion Photography</h5>
              <p>
                Our fashion photography services are designed to capture the
                essence of style, creativity, and individuality. Whether for
                lookbooks, magazines, advertising campaigns, or personal
                portfolios, we work closely with designers, models, and stylists
                to create stunning visuals that make a statement.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-100">
        <div className="container">
          <div className={`${style.form} row`}>
            <div className="col-md-6 mob_hide">
              <div className={style.form_left}>
                <div className={style.text_heading}>
                  <h2>
                    Hi,
                    <br />
                    We are Sandy Photography
                  </h2>
                  <p>Call us : 8295278827</p>
                  <p>Mail us : sndyphotographya1@gmail.com.</p>
                </div>
                <div className={style.couple}>
                  <img src={Couple} alt="couple" />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className={`${style.form_right} text_heading`}>
                <h2>Contact Us</h2>
                <p>Beautiful Moments Is Everything</p>
                <FormFields isModal={false} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
