import { NavLink } from "react-router-dom";
import { useState } from "react";
import styles from "./Navbar.module.css";
import ContactFormModal from '../Contact-Form-Modal/ContactFormModal';
import Logo from "../../assets/logo.svg";

const Navbar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  return (
    <section className={`${styles.home_floral_pattern}`}> 
      <nav
        className={` ${styles.navbar} navbar navbar-expand-lg bg-body-tertiary`}
      >
        <div className="container-fluid">
          <div>
            <NavLink
              to="/"
              className="nav-link active nav-item"
              aria-current="page"
            >
              <img
                className={`${styles.logo}`}
                src={Logo}
                alt="Sandy-photography-logo"
              />
            </NavLink>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setMenuOpen(!isMenuOpen)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse ${isMenuOpen ? "show" : ""}`}
            id="navbarSupportedContent"
          >
            <ul
              className={`${styles.navbarRight} navbar-nav me-auto mb-2 mb-lg-0`}
            >
              <li className="nav-item">
                <NavLink to="/" className={`${styles.navbarItem} nav-link`}>
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/portfolio"
                  className={`${styles.navbarItem} nav-link`}
                >
                  Portfolio
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/about-us"
                  className={`${styles.navbarItem} nav-link`}
                >
                  About Us
                </NavLink>
              </li>
              <li className="nav-item mob_hide">
                <ContactFormModal modalId="exampleModal" isBanner={false} />
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </section>
  );
};

export default Navbar;
