import styles from "./Portfolio.module.css";
import camera from "../../assets/camera.svg";
import ContactFormModal from "../../components/Contact-Form-Modal/ContactFormModal";
import PortfolioGallery from "../../components/Portfolio-Gallery/PortfolioGallery";
import { useEffect, useState } from "react";
import axios from "axios";

const Portfolio = () => {
  const [images, setImages] = useState([]);

  const fetchImages = async () => {
    try {
      const res = await axios.get("/db.json");
      const data = res.data; // Use res.data to access the parsed JSON data
      console.log(data.portfolioGallery);
      setImages(data.portfolioGallery);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    fetchImages();
  }, []);

  return (
    <>
      <section className={styles.portfolio_div}>
        <div className="container">
          <div className="row">
            <div className={styles.portfolio_text}>
              <h1>Capturing Stories Through Lens</h1>
              <p>
                Sndy Photography, we are dedicated to creating beautiful,
                timeless images that capture the essence of a moment. With a
                focus on story-telling, we strive to give our clients an
                experience that is unique and special.
              </p>
              <ContactFormModal modalId="exampleModal1" />
            </div>
            <div className={`${styles.portfolio_img} text-center`}>
              <img src={camera} alt="Wedding Couple" />
            </div>
          </div>
        </div>
      </section>
      <section className="mt-100">
        <div className="container">
          <div className={`${styles.text_heading} row`}>
            <h2>Our Portfolio</h2>
            <p>
              Explore our gallery to see how we bring each moment to life with
              creativity, precision, and passion. We believe that photography is
              an art form that captures the essence of life's most precious
              moments. Every photograph is a testament to our commitment to
              excellence and our dedication to creating timeless memories.
              <span className="hide_text_mob">
                Our diverse collection showcases our expertise in various
                photography styles, from weddings and cinematic moments to
                professional editing, product photography, and fashion
                photography.
              </span>
            </p>
          </div>
          <div className="row">
            <div className={styles.home_gallery}>
              <PortfolioGallery images={images} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Portfolio;
