import styles from "./Footer.module.css";
import footerLogo from "../../assets/footer-logo.svg";
import { NavLink } from "react-router-dom";
import mailIcon from "../../assets/email.svg";
import phoneIcon from "../../assets/phone.svg";

const Footer = () => {
  return (
    <section className={`${styles.footer_div} mt-100`}>
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <div className={styles.footer_about}>
              <img src={footerLogo} alt="sandy photography" />
              <p>
                At Sndy Photography, we understand that every moment is unique
                and deserves to be captured with care and creativity. Our team
                of skilled photographers is dedicated to turning your special
                occasions and important milestones into timeless memories. Our
                passion for photography creates memories in every moment. We
                take pride in offering a wide range of photography services like
                wedding, cinematic moments, professional editing, product
                photography, and fashion photography.
              </p>
            </div>
          </div>
          <div className="col-md-3 mob_hide">
            <div className={styles.footer_links}>
              <h5>Sndy photography</h5>
              <ul>
                <li className="nav-item">
                  <NavLink to="/" className={`${styles.navbarItem} nav-link`}>
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/portfolio"
                    className={`${styles.navbarItem} nav-link`}
                  >
                    Portfolio
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/about-us"
                    className={`${styles.navbarItem} nav-link`}
                  >
                    About Us
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            <div className={styles.footer_address}>
              <h5>Address</h5>
              <p>Near Dlf City Court, Sikanderpur, Sector 25, Gurgaon 122004</p>
              <p>
                <img src={phoneIcon} alt="phone-icon" /> 8295278827
              </p>
              <p>
                <img src={mailIcon} alt="mail-icon" />
                sndyphotographya1@gmail.com
              </p>
            </div>
          </div>
        </div>
        <hr className={styles.footer_line}></hr>
        <div className={styles.footer_bottom}>
          <p>
            ALL IMAGES, TEXTS, AND VIDEOS ARE COPYRIGHTED.
            ©2024 SNDYPHOTOGRAPHY- ALL RIGHTS RESERVED.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Footer;
