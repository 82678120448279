import styles from './App.module.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import Portfolio from './pages/Portfolio/Portfolio';
import AboutUs from './pages/About Us/AboutUs';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import ScrollToTop from './components/scroll-to-top/ScrollToTop'

function App() {
  return (
    <div className={styles.container}>
      <BrowserRouter>
      <ScrollToTop />
        <div className={styles.layout}>
          <Navbar />
          <Routes>
            <Route
              path="/"
              exact
              element={
                <div className={styles.main}>
                  <Home />
                </div>
              }
            />
             <Route
              path="/portfolio"
              exact
              element={
                <div className={styles.main}>
                  <Portfolio />
                </div>
              }
            />
            <Route
              path="/about-us"
              exact
              element={
                <div className={styles.main}>
                  <AboutUs />
                </div>
              }
            />
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
